import { JsonObject } from '@/types';
import { SOCIAL_MEDIA } from '@/enum';
import moment from 'moment';
// declare const window: any;

const btoken = 'meedu-live-teacher-token';
const cookieMaxAge = 86400 * 7;

const applyDomain = () => {
  const appHost = process.env.NEXT_PUBLIC_APP_HOST;
  if (!appHost) {
    return;
  }
  const hostParts = String(appHost).split('.');
  const lastTwoParts = hostParts.slice(-2).join('.');
  if (lastTwoParts.startsWith('.')) {
    return lastTwoParts.slice(1);
  }
  return lastTwoParts;
};

export const checkContainsBtoken = () => {
  return document.cookie.includes(btoken);
};

export const checkFrmCookieToGetToken = () => {
  const cookie = document.cookie;
  if (checkContainsBtoken()) {
    const token = cookie.split(';').find((eachCookie) => eachCookie.includes(btoken));
    if (token) {
      const tokenValue = token.split('=')[1];
      window.localStorage.setItem(btoken, tokenValue);
    }
  } else {
    return 'noCookie';
  }
};

export function getToken(): string {
  if (typeof window !== 'undefined') return window.localStorage.getItem(btoken) || '';
  return '';
}

export function setToken(data: string) {
  window.localStorage.setItem(btoken, data);
  const domain = applyDomain();
  console.log('domain', window.location.hostname);
  if (window.location.hostname === 'localhost') {
    document.cookie = `${btoken}=${data}; path=/; max-age=${cookieMaxAge};`;
  } else {
    document.cookie = `${btoken}=${data}; path=/; max-age=${cookieMaxAge};${domain ? ` domain=${domain};` : ''}`;
  }
}

export function clearToken() {
  window.localStorage.removeItem(btoken);
  const domain = applyDomain();
  if (window.location.hostname === 'localhost') {
    document.cookie = `${btoken}=; path=/; max-age=0;`;
  } else {
    document.cookie = `${btoken}=; path=/; max-age=0;${domain ? ` domain=${domain};` : ''}`;
  }
}

export function dateFormat(dateStr: string) {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('YYYY-MM-DD HH:mm');
}

export function dateWholeFormat(dateStr: string) {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('YYYY-MM-DD HH:mm:ss');
}

export function yearFormat(dateStr: string) {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('YYYY-MM-DD');
}

export function generateUUID(): string {
  let guid = '';
  for (let i = 1; i <= 32; i++) {
    const n = Math.floor(Math.random() * 16.0).toString(16);
    guid += n;
    if (i === 8 || i === 12 || i === 16 || i === 20) guid += '-';
  }
  return guid;
}

export function transformBase64ToBlob(base64: string, mime: string, filename: string): File {
  const arr = base64.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function getHost() {
  return window.location.protocol + '//' + window.location.host + '/';
}

export function inStrArray(array: string[], value: string): boolean {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === value) {
      return true;
    }
  }
  return false;
}

export function checkUrl(value: string) {
  let url = value;
  const str = url.substr(url.length - 1, 1);
  if (str !== '/') {
    url = url + '/';
  }
  return url;
}

export function passwordRules(value: string) {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{12,25}$/;
  const result = re.test(value);
  if (!result) {
    return '密码至少包含大写字母，小写字母，数字，且不少于12位';
  }
}

export function getUrl() {
  return window.location.protocol + '//' + window.location.host;
}

export function saveEditorKey(key: string) {
  window.localStorage.setItem('meedu-editor-key', key);
}

export function getEditorKey() {
  return window.localStorage.getItem('meedu-editor-key');
}

// export function codeRender(el: any) {
//   if (!el) {
//     return;
//   }
//   const blocks = el.querySelectorAll('pre') || el.querySelectorAll('code');
//   blocks.forEach((block: any) => {
//     window.hljs.highlightBlock(block);
//   });
//   return el;
// }

// export function latexRender(el: any) {
//   if (!el) {
//     return;
//   }
//   const reg1 = new RegExp('&nbsp;', 'g');
//   const reg2 = new RegExp('&amp;', 'g');
//   const reg3 = new RegExp('nbsp;', 'g');
//   const reg4 = new RegExp('amp;', 'g');
//   el.innerHTML = el.innerHTML.replace(reg1, '');
//   el.innerHTML = el.innerHTML.replace(reg2, '&');
//   el.innerHTML = el.innerHTML.replace(reg3, '');
//   el.innerHTML = el.innerHTML.replace(reg4, '');
//   window.renderMathInElement(el, {
//     delimiters: [
//       { left: '$$', right: '$$', display: true },
//       { left: '$', right: '$', display: false },
//       { left: '\\(', right: '\\)', display: false },
//       { left: '\\[', right: '\\]', display: true },
//     ],
//     macros: {
//       '\\ge': '\\geqslant',
//       '\\le': '\\leqslant',
//       '\\geq': '\\geqslant',
//       '\\leq': '\\leqslant',
//     },
//     options: {
//       skipHtmlTags: ['noscript', 'style', 'textarea', 'pre', 'code'],
//       // 跳过mathjax处理的元素的类名，任何元素指定一个类 tex2jax_ignore 将被跳过，多个累=类名'class1|class2'
//       ignoreHtmlClass: 'tex2jax_ignore',
//     },
//     svg: {
//       fontCache: 'global',
//     },
//     throwOnError: false,
//   });

//   return el;
// }

export function wechatUrlRules(url: string) {
  if (!url.substring(0, 8).match('https://') && !url.substring(0, 7).match('http://')) {
    return '地址必须携带http://或https://协议';
  }
}

/**
 * This function format a number to a string with decimal and comma
 * @param num - number need to format
 * @param styleVal check whether need to format by using currency
 * @param decimal  include how many decimal in the value
 * @param currencyVal currency to use
 * @param locales translation
 * @returns - number with comma
 */

export function numberFormat(
  num: number,
  styleVal: boolean = false,
  decimal: number,
  currencyVal: string = 'CNY',
  locales: string = 'zh-CN',
): string {
  return styleVal
    ? Number(num).toLocaleString(locales, {
        style: 'currency',
        currency: currencyVal,
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      })
    : Number(num).toLocaleString(locales, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
}

export const parsePrice = (price: number) => {
  return `USD ${price}`;
};

export const parseEnumSocialMedia = (key: SOCIAL_MEDIA, translationCallback: (value: string) => string) => {
  switch (key) {
    case SOCIAL_MEDIA.FACEBOOK:
      return translationCallback('global.facebook');
    case SOCIAL_MEDIA.INSTAGRAM:
      return translationCallback('global.instagram');
    case SOCIAL_MEDIA.XIAOHONGSHU:
      return translationCallback('global.redBook');
    case SOCIAL_MEDIA.DOUYIN:
      return translationCallback('global.douYin');
    case SOCIAL_MEDIA.YOUTUBE:
      return translationCallback('global.youtube');

    default:
      return '';
  }
};

export const getCommentTime = (dateStr: string) => {
  if (dateStr === '刚刚') {
    return '刚刚';
  }
  const interval = moment().diff(moment(dateStr), 'seconds');
  if (interval < 60) {
    return '刚刚';
  } else if (interval < 60 * 60) {
    const tempTime = Math.floor(interval / 60);
    return `${tempTime}分钟前`;
  } else if (interval < 60 * 60 * 24) {
    const tempTime = Math.floor(interval / (60 * 60));
    return `${tempTime}小时前`;
  } else if (interval < 60 * 60 * 24 * 7) {
    const tempTime = Math.floor(interval / (60 * 60 * 24));
    return `${tempTime}天前`;
  } else if (interval < 60 * 60 * 24 * 365) {
    return moment(dateStr).utcOffset(0).format('MM-DD');
  } else {
    return moment(dateStr).utcOffset(0).format('YYYY-MM-DD');
  }
};

export const getSearchQuery = (search: JsonObject) => {
  const params = new URLSearchParams();
  Object.keys(search).forEach((key) => {
    if (search[key]) params.set(key, search[key]);
  });
  return params.toString();
};

type VideoParseInfo = {
  poster: string;
  duration: number;
};
export function parseVideo(file: File): Promise<VideoParseInfo> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.muted = true;
    video.setAttribute('src', URL.createObjectURL(file));
    video.setAttribute('autoplay', 'autoplay');
    video.setAttribute('crossOrigin', 'anonymous'); //设置跨域 否则toDataURL导出图片失败
    video.setAttribute('width', '400'); //设置大小，如果不设置，下面的canvas就要按需设置
    video.setAttribute('height', '300');
    video.currentTime = 7; //视频时长，一定要设置，不然大概率白屏
    video.addEventListener('loadeddata', function () {
      const canvas = document.createElement('canvas'),
        width = video.width, //canvas的尺寸和图片一样
        height = video.height;
      canvas.width = width; //画布大小，默认为视频宽高
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return reject('无法捕获视频帧');
      }
      ctx.drawImage(video, 0, 0, width, height); //绘制canvas
      const dataURL = canvas.toDataURL('image/png'); //转换为base64
      video.remove();
      const info: VideoParseInfo = {
        poster: dataURL,
        duration: parseInt(video.duration + ''),
      };
      return resolve(info);
    });
  });
}
/**
 * This function copy text to clipboard
 * @param value - value to be copied
 * @returns void
 */
export const copy = async (value: string) => {
  try {
    return navigator.clipboard
      .writeText(value)
      .then(() => {
        return 'successCopy';
      })
      .catch(() => {
        throw 'failedCopy';
      });
  } catch (error) {
    const textArea = document.createElement('textarea');
    textArea.value = value;

    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
      return 'successCopy';
    } catch (error) {
      return 'failedCopy';
    } finally {
      textArea.remove();
    }
  }
};

export const isServer = typeof window === 'undefined';
export const isClient = !isServer;

export const getDomainFromCMS = async (hostname: string, project: string = 'doo_academy_cn') => {
  const apiURL = process.env.NEXT_PUBLIC_CMS_URL || '';
  const apiKey = process.env.NEXT_PUBLIC_CMS_APIKEY || '';
  const platform = 'lecturer';
  const envMap = {
    localhost: 'dev',
    dev: 'dev',
    stg: 'stg',
    uat: 'uat',
    default: 'prd',
  };

  // Fallback data
  const fallback = [
    {
      host: 'tutor.dooacademy.com',
      name: 'doo_academy_global',
      is_active: 1,
      is_valid: 1,
      created_by: 1,
      category: 'lecturer',
      environment: 'prd',
    },
    {
      host: 'tutor-cn.dooacademy.com',
      name: 'doo_academy_cn',
      is_active: 1,
      is_valid: 1,
      created_by: 1,
      category: 'lecturer',
      environment: 'prd',
    },
  ]

  let currentEnv = Object.keys(envMap).find((env) => hostname.includes(env)) || 'default';
  currentEnv = envMap[currentEnv as keyof typeof envMap];

  const url = `${apiURL}api/v1/public/domain/list?name=${project}&environment=${currentEnv}&category=${platform}`;
  const urlOption = {headers: {'Content-Type': 'application/json', apiKey}}
  try {
    const data = await fetchWithRetry(url, urlOption, 3, 1000, 2000);

    return data?.data?.dynamicList;
  } catch (error) {
    // console.error('Failed to fetch:', error.message);
  }

  // Return fallback data immediately
  // console.log('Fallback data:', fallback);
  return fallback;
};

export async function fetchWithRetry(
  url: string, 
  options = {},
  retries = 3,
  delay = 1000,
  timeout = 2000
) {
  // console.warn(`[fetchWithRetry] Starting request to URL: ${url}`);
  
  const controller = new AbortController();
  const signal = controller.signal;
  const timeoutId = setTimeout(() => {
    controller.abort();
    // console.warn(`[fetchWithRetry] Request timed out after ${timeout}ms`);
  }, timeout);

  try {
    const response = await fetch(url, { ...options, signal });
    clearTimeout(timeoutId);

    if (!response.ok) {
      // console.warn(`[fetchWithRetry] Received non-OK response. Status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // console.warn(`[fetchWithRetry] Successfully fetched data from ${url}`);
    return await response.json();
  } catch (error) {
    clearTimeout(timeoutId);

    if (retries > 0 && (error instanceof Error && error.name === 'AbortError')) {
      // console.warn(`[fetchWithRetry] Retrying request... (${retries} retries left)`);
      await new Promise((resolve) => setTimeout(resolve, delay));
      return fetchWithRetry(url, options, retries - 1, delay, timeout);
    }

    // console.error(`[fetchWithRetry] Request failed after retries. Unknown error: ${String(error)}`);
    throw error;
  }
}
